import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Ul from './Ul'
import Li from './Li'
import {useNavigate} from 'react-router-dom'

const AdvantagesText = () => {

  const navigate = useNavigate()

  return (
    <>
      <H2>VANTAGGI</H2>
      <Ul>
        <Li>
          <span>Semplicità nella compilazione della richiesta</span>
        </Li>
        <Li>
          <span>Rapidità e comodità nell’erogazione del prestito</span>
        </Li>
        <Li>
          <span>Durata flessibile del prestito 24, 36, 46, 60, 72, 84, 96, 108, 120 mesi</span>
        </Li>
        <Li>
          <span>Finanziamento sicuro perché protetto da polizza assicurativa</span>
        </Li>
        <Li>
          <span>Possibilità di rinnovare anticipatamente nel rispetto della normativa vigente</span>
        </Li>
        <Li>
          <span>
            Facilità nell’erogazione del finanziamento, anche in presenza di altri prestiti e disguidi finanziari
          </span>
        </Li>
        <Li>
          <span>
            Importo massimo erogabile determinato in base all’ammontare dello stipendio o della pensione nonché in base
            alla durata del prestito
          </span>
        </Li>
        <Li>
          <span>
            TAEG e TAN da valutare in sede della singola proposta di finanziamento; per avere un riferimento è possibile
            verificare gli esempi riportati all’interno dei canali QuintoPuoi. Si ricorda che Banca Sistema opera in
            coerenza e al di sotto dei tassi usura previsti dal TEGM riportato e aggiornato trimestralmente all’interno
            della sezione Trasparenza (*)
          </span>
        </Li>
        <Li>
          <span>TAEG max applicabile 20,76%.</span>
        </Li>
        <Li>
          <span>Possibilità di estinguere anticipatamente e recuperare gli interessi non maturati (**)</span>
        </Li>
        <Li>
          <span>Possibilità di accedere alla cessione del quinto anche online tramite <A onClick={() => navigate('/firma-digitale')}>SPID/riconoscimento digitale</A>.</span>
        </Li>
      </Ul>
      <P style={{ fontSize: '1rem' }}>
        (*) Banca d’Italia comunica i Tassi Effettivi Globali Medi (TEGM) applicati ogni trimestre dagli intermediari,
        rilevati su delega del Ministero dell’Economia e delle Finanze ai sensi della Legge n. 108/96 (antiusura) in
        base alle categorie omogenee di operazioni definite annualmente dal MEF stesso.
        <br />
        (**) Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul Credito
        ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti in attività
        finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del prodotto
        QuintoPuoi. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..
      </P>
    </>
  )
}

// region Style
const A = styled.a`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`
// endregion
export default AdvantagesText
