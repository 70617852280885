import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import NativeSelect from '../../commons/NativeSelect'
import P from '../../commons/P'
import FormCollapsable from './FormCollapsable'
import FormLegend from './FormLegend'
import { useController, useForm } from 'react-hook-form'
import axios from 'axios'
import { baseUrl } from '../../../config'
//@ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3'
import { useNavigate } from 'react-router-dom'
import Select, { Options } from '../../commons/Select'
import dreamIcon from '../../../img/ico_dream.svg'
import identityIcon from '../../../images/ico_identity.svg'
import moneyIcon from '../../../images/ico_money.svg'
import timeIcon from '../../../images/ico_time.svg'
import Loading from '../../commons/Loading'
import { UtmsType } from '../../pages/landingGenerale/LandingGenerale'
import { createCampaign } from '../../helpers'

interface LandingPreventivatoreProps {
  routeAfterSubmit?: string
  lpType?: string
  teadsConversionType?: string
}
type FormValues = {
  lp: string
  tipologia: string
  macroprofessione: string
  importo: string
  durata: string
  nome: string
  cognome: string
  annonascita: string
  citta: string
  provincia: string
  telefono: string
  email: string
  conferma_email: string
  p1: String
  p2: string
  p3: string
  reCaptchaToken: string
  utms: UtmsType
  campagna: any
}

const FormInput = (props: any) => {
  const { control, name } = props
  const { field } = useController({ control, name })

  useEffect(() => {
    if (props.id) {
      const inputElem = document.getElementById('email-confirmation') as HTMLInputElement
      inputElem.setCustomValidity('')
    }
  }, [field, props.id])

  return (
    <InputContainer>
      <Input {...field} aria-label={name} value={field.value || ''} {...props} />
    </InputContainer>
  )
}

const LandingPreventivatore = ({
  routeAfterSubmit = '',
  lpType = '',
  teadsConversionType = ''
}: LandingPreventivatoreProps) => {
  const navigate = useNavigate()

  const [teadsSent, setTeadsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const areEqualEmails = (data: any) => {
    const emailConfirmationInput = document.getElementById('email-confirmation') as HTMLInputElement
    if (data.email !== data.conferma_email) {
      emailConfirmationInput && emailConfirmationInput.setCustomValidity('Gli indirizzi Email non combaciano.')
      emailConfirmationInput.reportValidity()
      return false
    } else {
      emailConfirmationInput && emailConfirmationInput.setCustomValidity('')
      return true
    }
  }

  const { register, handleSubmit, control, setValue } = useForm<FormValues>()
  const formSubmit = handleSubmit((data, event) => {
    if (!areEqualEmails(data)) return
    event?.preventDefault()
    setIsLoading(true)

    if (teadsConversionType) {
      //@ts-ignore
      const teads = window.teads_e
      if (teads && !teadsSent) {
        setTeadsSent(true)
        teads.push({
          conversionType: teadsConversionType
        })
      }
    }
    // data.utms = JSON.parse(localStorage.getItem('qp_utms') || "")

    ReactRecaptcha3.getToken().then(
      async (token: string) => {
        data.nome = data.nome.trim()
        data.cognome = data.cognome.trim()
        data.citta = data.citta.trim()
        data.telefono = data.telefono.trim()

        if(!data.nome || !data.cognome || !data.citta ||!data.telefono)  {
          console.error('Name, surname, city or phone number missing')
          setIsLoading(false)
          return
        }

        data.reCaptchaToken = token
        data.lp = lpType
        data.p1 = 'si'
        data.p2 = data.p2 ? 'si' : 'no'
        data.p3 = data.p3 ? 'si' : 'no'
        data.utms = JSON.parse(localStorage.getItem('qp_utms') || '{}')

        data.campagna = createCampaign(data.utms, lpType)

        await axios
          .post(`${baseUrl}/preventivatore`, data)
          .then(() => routeAfterSubmit && navigate(routeAfterSubmit))
          .catch((e) => {
            console.log('Error submitting', e.message)
            // TODO: mostrare modale di errore
          })
          .finally(() => setIsLoading(false))
      },
      (e: string) => {
        // TODO: mostrare modale errore
        setIsLoading(false)
        console.error('Error retrieving recaptcha token', e)
      }
    )
  })

  return (
    <Container>
      {isLoading && <Loading />}
      <FormContainer>
        <Form id={'preventivatore2'} onSubmit={formSubmit}>
          <FirstSection>
            <StyledH2>RICHIEDI UN PREVENTIVO GRATUITO E SENZA IMPEGNO</StyledH2>
            <StyledP>
              Compila subito il form. Verrai ricontattato dai nostri consulenti per avere una proposta personalizzata.
            </StyledP>
            <StyledP>
              Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a tempo indeterminato da
              aziende SpA/Srl con più di 15 dipendenti, dipendenti pubblici/statali/parapubblici
            </StyledP>
            <Row>
              <SelectContainer>
                <Select
                  isTextBold={true}
                  name={'tipologia'}
                  options={typeList}
                  placeHolderText={'IL MIO PROGETTO'}
                  icon={dreamIcon}
                  dataFromSelect={({ value }: any) => {
                    setValue('tipologia', value, { shouldDirty: true })
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <Select
                  required={true}
                  isTextBold={true}
                  name={'macroprofessione'}
                  options={customerCategoryList}
                  placeHolderText={'CHI SONO*'}
                  icon={identityIcon}
                  dataFromSelect={({ value }: any) => {
                    setValue('macroprofessione', value, { shouldDirty: true })
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <Select
                  required={true}
                  isTextBold={true}
                  name={'importo'}
                  options={amountList}
                  placeHolderText={'IMPORTO*'}
                  icon={moneyIcon}
                  dataFromSelect={({ value }: any) => {
                    setValue('importo', value, { shouldDirty: true })
                  }}
                />
              </SelectContainer>
              <SelectContainer>
                <Select
                  required={true}
                  isTextBold={true}
                  name={'durata'}
                  options={durationList}
                  placeHolderText={'DURATA*'}
                  icon={timeIcon}
                  dataFromSelect={({ value }: any) => {
                    setValue('durata', value, { shouldDirty: true })
                  }}
                />
              </SelectContainer>
            </Row>
          </FirstSection>
          <SecondSection>
            <Row>
              <FormInput
                control={control}
                name={'nome'}
                type={'text'}
                id={'name'}
                placeholder={'Nome*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'cognome'}
                type={'text'}
                id={'surname'}
                placeholder={'Cognome*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'annonascita'}
                type={'number'}
                id={'birthday'}
                placeholder={'Anno di nascita*'}
                min={'1919'}
                max={'2001'}
                required={true}
              />
              <FormInput
                control={control}
                name={'citta'}
                type={'text'}
                id={'city'}
                placeholder={'Città*'}
                required={true}
              />
              <ProvinceSelectContainer>
                <NativeSelect
                  required={true}
                  name={'provincia'}
                  options={provinceList}
                  placeHolderText={'Provincia*'}
                  dataFromSelect={(value) => {
                    setValue('provincia', value, { shouldDirty: true })
                  }}
                />
              </ProvinceSelectContainer>
              <FormInput
                control={control}
                name={'telefono'}
                type={'tel'}
                id={'telefono'}
                placeholder={'Telefono*'}
                minLength={8}
                required={true}
              />
              <FormInput
                control={control}
                name={'email'}
                type={'email'}
                id={'email'}
                placeholder={'Email*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'conferma_email'}
                type={'email'}
                id={'email-confirmation'}
                placeholder={'Conferma Email*'}
                autocomplete={'new-password'}
                required={true}
              />
              <CollapsableContainer>
                <FormCollapsable />
              </CollapsableContainer>
              <CheckContainer>
                <CheckInnerContainer>
                  <Checkbox {...register('p2')} type={'checkbox'} id={'privacy'} />
                  <CheckboxLabel htmlFor={'privacy'}>
                    Desidero ricevere comunicazioni su iniziative promozionali curate dalla Banca e relative a prodotti
                    della Banca (compreso marketing diretto) o di società Terze, ivi compresa l’organizzazione di
                    eventi, anche attraverso sistemi automatizzati (sms, mms, fax e posta elettronica) – Finalità 3
                    lettera a) e c) dell’informativa privacy.
                  </CheckboxLabel>
                </CheckInnerContainer>
              </CheckContainer>
              <CheckContainer>
                <CheckInnerContainer>
                  <Checkbox {...register('p3')} type={'checkbox'} id={'marketing'} />
                  <CheckboxLabel htmlFor={'marketing'}>
                    Desidero ricevere comunicazioni informative o offerte promozionali da Terzi, anche attraverso
                    sistemi automatizzati (sms, mms, fax e posta elettronica), di loro prodotti o servizi - Finalità 3
                    lettera d) dell’informativa privacy.
                  </CheckboxLabel>
                </CheckInnerContainer>
              </CheckContainer>
            </Row>
            <Row>
              <SubmitContainer>
                <SubmitButton type={'submit'}>RICHIEDI PREVENTIVO GRATUITO**</SubmitButton>
              </SubmitContainer>
            </Row>
            <Row>
              <FormLegend />
            </Row>
          </SecondSection>
        </Form>
      </FormContainer>
    </Container>
  )
}

//region Data

const typeList: Options[] = [
  { label: 'Liquidità', value: 'Liquidita' },
  { label: 'Spese Mediche', value: 'Mediche' },
  { label: 'Consolidamento Debiti', value: 'Consolidamento' },
  { label: 'Computer / Hi-Tech', value: 'Hi-Tech' },
  { label: 'Acquisto Auto / Moto', value: 'Auto' },
  { label: 'Acquisto Mobili', value: 'Mobili' },
  { label: 'Acquisto / Ristrutturazione', value: 'Casa' },
  { label: 'Vacanze / Viaggi', value: 'Viaggi' },
  { label: 'Altri Progetti', value: 'Altro' }
]
const customerCategoryList: Options[] = [
  {
    label: 'Dipendente Pubblico',
    value: 'Pubblico-Statale'
  },
  {
    label: 'Dipendente azienda privata SPA/srl',
    value: 'Privato'
  },
  {
    label: 'Pensionato',
    value: 'Pensionato'
  },
  {
    label: 'Senza reddito',
    value: 'SENZA REDDITO'
  },
  {
    label: 'Partita IVA / Autonomo',
    value: 'P.IVA e AUTONOMO'
  },
  {
    label: 'Altro',
    value: 'ALTRO'
  }
]
const amountList: Options[] = [
  {
    label: 'Fino a 5.000 €',
    value: '5000'
  },
  {
    label: 'Fino a 10.000 €',
    value: '10000'
  },
  {
    label: 'Fino a 15.000 €',
    value: '15000'
  },
  {
    label: 'Fino a 20.000 €',
    value: '20000'
  },
  {
    label: 'Fino a 25.000 €',
    value: '25000'
  },
  {
    label: 'Fino a 30.000 €',
    value: '30000'
  },
  {
    label: 'Fino a 50.000 €',
    value: '50000'
  },
  {
    label: 'Fino a 60.000 €',
    value: '60000'
  },
  {
    label: 'Oltre 60.000 €',
    value: 'Oltre'
  }
]
const durationList: Options[] = [
  {
    label: '24 rate',
    value: '24'
  },
  {
    label: '36 rate',
    value: '36'
  },
  {
    label: '48 rate',
    value: '48'
  },
  {
    label: '60 rate',
    value: '60'
  },
  {
    label: '72 rate',
    value: '72'
  },
  {
    label: '84 rate',
    value: '84'
  },
  {
    label: '96 rate',
    value: '96'
  },
  {
    label: '108 rate',
    value: '108'
  },
  {
    label: '120 rate',
    value: '120'
  }
]
const provinceList: Options[] = [
  { value: '', label: 'Provincia*' },
  { value: 'agrigento', label: 'Agrigento' },
  { value: 'alessandria', label: 'Alessandria' },
  { value: 'ancona', label: 'Ancona' },
  { value: 'aosta', label: 'Aosta' },
  { value: 'arezzo', label: 'Arezzo' },
  { value: 'ascoli-piceno', label: 'Ascoli Piceno' },
  { value: 'asti', label: 'Asti' },
  { value: 'avellino', label: 'Avellino' },
  { value: 'bari', label: 'Bari' },
  { value: 'barletta-andria-trani', label: 'Barletta-Andria-Trani' },
  { value: 'belluno', label: 'Belluno' },
  { value: 'benevento', label: 'Benevento' },
  { value: 'bergamo', label: 'Bergamo' },
  { value: 'biella', label: 'Biella' },
  { value: 'bologna', label: 'Bologna' },
  { value: 'bolzano', label: 'Bolzano' },
  { value: 'brescia', label: 'Brescia' },
  { value: 'brindisi', label: 'Brindisi' },
  { value: 'cagliari', label: 'Cagliari' },
  { value: 'caltanissetta', label: 'Caltanissetta' },
  { value: 'campobasso', label: 'Campobasso' },
  { value: 'carbonia-iglesias', label: 'Carbonia-Iglesias' },
  { value: 'caserta', label: 'Caserta' },
  { value: 'catania', label: 'Catania' },
  { value: 'catanzaro', label: 'Catanzaro' },
  { value: 'chieti', label: 'Chieti' },
  { value: 'como', label: 'Como' },
  { value: 'cosenza', label: 'Cosenza' },
  { value: 'cremona', label: 'Cremona' },
  { value: 'crotone', label: 'Crotone' },
  { value: 'cuneo', label: 'Cuneo' },
  { value: 'enna', label: 'Enna' },
  { value: 'fermo', label: 'Fermo' },
  { value: 'ferrara', label: 'Ferrara' },
  { value: 'firenze', label: 'Firenze' },
  { value: 'foggia', label: 'Foggia' },
  { value: 'forli-cesena', label: 'Forlì-Cesena' },
  { value: 'frosinone', label: 'Frosinone' },
  { value: 'genova', label: 'Genova' },
  { value: 'gorizia', label: 'Gorizia' },
  { value: 'grosseto', label: 'Grosseto' },
  { value: 'imperia', label: 'Imperia' },
  { value: 'isernia', label: 'Isernia' },
  { value: 'l-aquila', label: "L'Aquila" },
  { value: 'la-spezia', label: 'La Spezia' },
  { value: 'latina', label: 'Latina' },
  { value: 'lecce', label: 'Lecce' },
  { value: 'lecco', label: 'Lecco' },
  { value: 'livorno', label: 'Livorno' },
  { value: 'lodi', label: 'Lodi' },
  { value: 'lucca', label: 'Lucca' },
  { value: 'macerata', label: 'Macerata' },
  { value: 'mantova', label: 'Mantova' },
  { value: 'massa-e-carrara', label: 'Massa e Carrara' },
  { value: 'matera', label: 'Matera' },
  { value: 'medio-campidano', label: 'Medio Campidano' },
  { value: 'messina', label: 'Messina' },
  { value: 'milano', label: 'Milano' },
  { value: 'modena', label: 'Modena' },
  { value: 'monza-e-brianza', label: 'Monza e Brianza' },
  { value: 'napoli', label: 'Napoli' },
  { value: 'novara', label: 'Novara' },
  { value: 'nuoro', label: 'Nuoro' },
  { value: 'ogliastra', label: 'Ogliastra' },
  { value: 'olbia-tempio', label: 'Olbia-Tempio' },
  { value: 'oristano', label: 'Oristano' },
  { value: 'padova', label: 'Padova' },
  { value: 'palermo', label: 'Palermo' },
  { value: 'parma', label: 'Parma' },
  { value: 'pavia', label: 'Pavia' },
  { value: 'perugia', label: 'Perugia' },
  { value: 'pesaro-e-urbino', label: 'Pesaro e Urbino' },
  { value: 'pescara', label: 'Pescara' },
  { value: 'piacenza', label: 'Piacenza' },
  { value: 'pisa', label: 'Pisa' },
  { value: 'pistoia', label: 'Pistoia' },
  { value: 'pordenone', label: 'Pordenone' },
  { value: 'potenza', label: 'Potenza' },
  { value: 'prato', label: 'Prato' },
  { value: 'ragusa', label: 'Ragusa' },
  { value: 'ravenna', label: 'Ravenna' },
  { value: 'reggio-calabria', label: 'Reggio Calabria' },
  { value: 'reggio-emilia', label: 'Reggio Emilia' },
  { value: 'rieti', label: 'Rieti' },
  { value: 'rimini', label: 'Rimini' },
  { value: 'roma', label: 'Roma' },
  { value: 'rovigo', label: 'Rovigo' },
  { value: 'salerno', label: 'Salerno' },
  { value: 'sassari', label: 'Sassari' },
  { value: 'savona', label: 'Savona' },
  { value: 'siena', label: 'Siena' },
  { value: 'siracusa', label: 'Siracusa' },
  { value: 'sondrio', label: 'Sondrio' },
  { value: 'taranto', label: 'Taranto' },
  { value: 'teramo', label: 'Teramo' },
  { value: 'terni', label: 'Terni' },
  { value: 'torino', label: 'Torino' },
  { value: 'trapani', label: 'Trapani' },
  { value: 'trento', label: 'Trento' },
  { value: 'treviso', label: 'Treviso' },
  { value: 'trieste', label: 'Trieste' },
  { value: 'udine', label: 'Udine' },
  { value: 'varese', label: 'Varese' },
  { value: 'venezia', label: 'Venezia' },
  { value: 'verbano-cusio-ossola', label: 'Verbano Cusio Ossola' },
  { value: 'vercelli', label: 'Vercelli' },
  { value: 'verona', label: 'Verona' },
  { value: 'vibo-valentia', label: 'Vibo Valentia' },
  { value: 'vicenza', label: 'Vicenza' },
  { value: 'viterbo', label: 'Viterbo' }
]

//endregion

// region Style
const Container = styled.div`
  @media (min-width: 992px) {
    order: 2;
  }
  @media (min-width: 992px) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const FormContainer = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
`
const Form = styled.form``
const FirstSection = styled.div`
  text-align: initial;
  @media (min-width: 769px) {
    padding: 4rem 4rem 2rem 4rem;
  }
  background: #222;
  color: #fff;
  border-radius: 33px 33px 0 0;
  padding: 1.5rem;
  position: relative;
  position: relative;
  padding-bottom: 1.5rem;
  width: 100%;
  max-width: 114rem;
  transition: padding 0.1s ease-out;
  z-index: 10;
  :after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #222222 transparent transparent transparent;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: -9px;
    transform: translateX(-50%);
  }
`
const SecondSection = styled.div`
  @media (min-width: 769px) {
    padding: 4rem;
  }
  padding: 1.5rem;
  position: relative;
`
const StyledH2 = styled(H2)`
  @media (min-width: 769px) {
    font-size: 28px;
    letter-spacing: 1px;
  }
  font-size: 25px;
  letter-spacing: 0;
  margin: 0;
`
const StyledP = styled(P)`
  font-size: 19px;
  line-height: 1.3;
  letter-spacing: 0;
  color: #aeaeae;
  margin-top: 2px;
`
const Row = styled.div`
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
`
const SelectContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 1rem !important;
  position: relative;
  width: 100%;
`
const ProvinceSelectContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  width: 100%;
`
const CollapsableContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 1.5rem !important;
  position: relative;
  width: 100%;
`
const SubmitContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  padding-left: 8px;
  padding-right: 8px;
  text-align: center !important;
  position: relative;
  width: 100%;
`
const CheckContainer = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  position: relative;
  width: 100%;
`
const CheckInnerContainer = styled.div`
  position: relative;
  display: block;
  padding-left: 1.25rem;
`
const Checkbox = styled.input<any>`
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  box-sizing: border-box;
  padding: 0;
`
const CheckboxLabel = styled.label`
  text-align: initial;
  font-size: 9px;
  display: inline-block;
  margin-left: 8px;
`

const InputContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  width: 100%;
`
const Input = styled.input`
  background: #e8e8e8;
  width: 100%;
  border: solid 1px #fff;
  color: #9b9b9b;
  font-weight: 700;
  font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  display: block;
  -webkit-appearance: none;
  line-height: 1;
  font-size: 1.3rem;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 1.5rem;
  &:focus {
    outline: none;
  }
`
const SubmitButton = styled.button`
  background-image: linear-gradient(112deg, #fac963 0%, #eba918 51%, #fed033 100%);
  font-size: 16px;
  color: #fff;
  letter-spacing: 0;
  cursor: pointer;
  position: relative;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.1s ease-out;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 3rem;
  border: none;
`
// endregion

export default LandingPreventivatore
