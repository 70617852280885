import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SubmitButton from './SubmitButton'
import PreventivatoreFormHeader from './PreventivatoreFormHeader'
import TfsFormHeader from './TfsFormHeader'
import P from '../commons/P'
import { useNavigate } from 'react-router-dom'
import { useController, useForm } from 'react-hook-form'
import axios from 'axios'
import { baseUrl } from '../../config'
import FormCollapsable from '../landingCommons/landingPreventivatore/FormCollapsable'
import Select, { Options } from '../commons/Select'
//@ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3'
import identityIcon from '../../images/ico_identity.svg'
import moneyIcon from '../../images/ico_money.svg'
import timeIcon from '../../images/ico_time.svg'
import dreamIcon from '../../img/ico_dream.svg'
import NativeSelect from '../commons/NativeSelect'
import Loading from '../commons/Loading'
import { UtmsType } from '../pages/landingGenerale/LandingGenerale'
import { createCampaign } from '../helpers'

interface PreventivatoreFromProps {
  isTfs?: boolean
  routeAfterSubmit?: string
  lpType?: string
}
type FormValues = {
  lp: string
  tipologia: string
  macroprofessione: string
  importo: string
  durata: string
  nome: string
  cognome: string
  annonascita: string
  citta: string
  provincia: string
  telefono: string
  email: string
  conferma_email: string
  p1: string
  p2: string
  p3: string
  reCaptchaToken: string
  utms: UtmsType
  campagna: any
}

const FormInput = (props: any) => {
  const { control, name } = props
  const { field } = useController({ control, name })

  useEffect(() => {
    if (props.id) {
      const inputElem = document.getElementById('email-confirmation') as HTMLInputElement
      inputElem.setCustomValidity('')
    }
  }, [field, props.id])

  return (
    <InputContainer>
      <Input {...field} aria-label={name} value={field.value || ''} {...props} />
    </InputContainer>
  )
}

const PreventivatoreForm = ({ isTfs = false, routeAfterSubmit = '', lpType = '' }: PreventivatoreFromProps) => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const areEqualEmails = (data: any) => {
    if (data.email !== data.conferma_email) {
      const emailConfirmationInput = document.getElementById('email-confirmation') as HTMLInputElement
      emailConfirmationInput && emailConfirmationInput.setCustomValidity('Gli indirizzi Email non combaciano.')
      emailConfirmationInput.reportValidity()
      return false
    } else {
      return true
    }
  }

  const { register, handleSubmit, control, setValue } = useForm<FormValues>()
  const formSubmit = handleSubmit((data, event) => {
    event?.preventDefault()
    if (!areEqualEmails(data)) return
    setIsLoading(true)

    ReactRecaptcha3.getToken().then(
      async (token: string) => {
        data.nome = data.nome.trim()
        data.cognome = data.cognome.trim()
        data.citta = data.citta.trim()
        data.telefono = data.telefono.trim()

        if(!data.nome || !data.cognome || !data.citta ||!data.telefono)  {
          console.error('Name, surname, city or phone number missing')
          setIsLoading(false)
          return
        }
        data.reCaptchaToken = token
        data.lp = lpType
        data.p1 = 'si'
        data.p2 = data.p2 ? 'si' : 'no'
        data.p3 = data.p3 ? 'si' : 'no'
        data.utms = JSON.parse(localStorage.getItem('qp_utms') || '{}')

        data.campagna = createCampaign(data.utms, lpType)

        await axios
          .post(`${baseUrl}/preventivatore`, data)
          .then(() => {
            routeAfterSubmit && navigate(routeAfterSubmit)
          })
          .catch((e) => {
            console.log('Error submitting', e.message)
            // TODO: mostrare modale di errore
          })
          .finally(() => {
            setIsLoading(false)
          })
      },
      (e: string) => {
        setIsLoading(false)
        // TODO: mostrare modale errore
        console.error('Error retrieving recaptcha token', e)
      }
    )
  })

  const typeList: Options[] = [
    { label: 'Liquidità', value: 'Liquidita' },
    { label: 'Spese Mediche', value: 'Mediche' },
    { label: 'Consolidamento Debiti', value: 'Consolidamento' },
    { label: 'Computer / Hi-Tech', value: 'Hi-Tech' },
    { label: 'Acquisto Auto / Moto', value: 'Auto' },
    { label: 'Acquisto Mobili', value: 'Mobili' },
    { label: 'Acquisto / Ristrutturazione', value: 'Casa' },
    { label: 'Vacanze / Viaggi', value: 'Viaggi' },
    { label: 'Altri Progetti', value: 'Altro' }
  ]
  const customerCategoryList: Options[] = [
    {
      label: 'Dipendente Pubblico',
      value: 'Pubblico-Statale'
    },
    {
      label: 'Dipendente azienda privata SPA/srl',
      value: 'Privato'
    },
    {
      label: 'Pensionato',
      value: 'Pensionato'
    },
    {
      label: 'Senza reddito',
      value: 'SENZA REDDITO'
    },
    {
      label: 'Partita IVA / Autonomo',
      value: 'P.IVA e AUTONOMO'
    },
    {
      label: 'Altro',
      value: 'ALTRO'
    }
  ]
  const amountList: Options[] = [
    {
      label: 'Fino a 5.000 €',
      value: '5000'
    },
    {
      label: 'Fino a 10.000 €',
      value: '10000'
    },
    {
      label: 'Fino a 15.000 €',
      value: '15000'
    },
    {
      label: 'Fino a 20.000 €',
      value: '20000'
    },
    {
      label: 'Fino a 25.000 €',
      value: '25000'
    },
    {
      label: 'Fino a 30.000 €',
      value: '30000'
    },
    {
      label: 'Fino a 50.000 €',
      value: '50000'
    },
    {
      label: 'Fino a 60.000 €',
      value: '60000'
    },
    {
      label: 'Oltre 60.000 €',
      value: 'Oltre'
    }
  ]
  const durationList: Options[] = [
    {
      label: '24 rate',
      value: '24'
    },
    {
      label: '36 rate',
      value: '36'
    },
    {
      label: '48 rate',
      value: '48'
    },
    {
      label: '60 rate',
      value: '60'
    },
    {
      label: '72 rate',
      value: '72'
    },
    {
      label: '84 rate',
      value: '84'
    },
    {
      label: '96 rate',
      value: '96'
    },
    {
      label: '108 rate',
      value: '108'
    },
    {
      label: '120 rate',
      value: '120'
    }
  ]
  const provinceList: Options[] = [
    { value: '', label: 'Provincia*' },
    { value: 'agrigento', label: 'Agrigento' },
    { value: 'alessandria', label: 'Alessandria' },
    { value: 'ancona', label: 'Ancona' },
    { value: 'aosta', label: 'Aosta' },
    { value: 'arezzo', label: 'Arezzo' },
    { value: 'ascoli-piceno', label: 'Ascoli Piceno' },
    { value: 'asti', label: 'Asti' },
    { value: 'avellino', label: 'Avellino' },
    { value: 'bari', label: 'Bari' },
    { value: 'barletta-andria-trani', label: 'Barletta-Andria-Trani' },
    { value: 'belluno', label: 'Belluno' },
    { value: 'benevento', label: 'Benevento' },
    { value: 'bergamo', label: 'Bergamo' },
    { value: 'biella', label: 'Biella' },
    { value: 'bologna', label: 'Bologna' },
    { value: 'bolzano', label: 'Bolzano' },
    { value: 'brescia', label: 'Brescia' },
    { value: 'rindisi', label: 'Brindisi' },
    { value: 'cagliari', label: 'Cagliari' },
    { value: 'caltanissetta', label: 'Caltanissetta' },
    { value: 'campobasso', label: 'Campobasso' },
    { value: 'carbonia-iglesias', label: 'Carbonia-Iglesias' },
    { value: 'caserta', label: 'Caserta' },
    { value: 'catania', label: 'Catania' },
    { value: 'catanzaro', label: 'Catanzaro' },
    { value: 'chieti', label: 'Chieti' },
    { value: 'como', label: 'Como' },
    { value: 'cosenza', label: 'Cosenza' },
    { value: 'cremona', label: 'Cremona' },
    { value: 'crotone', label: 'Crotone' },
    { value: 'cuneo', label: 'Cuneo' },
    { value: 'enna', label: 'Enna' },
    { value: 'fermo', label: 'Fermo' },
    { value: 'ferrara', label: 'Ferrara' },
    { value: 'firenze', label: 'Firenze' },
    { value: 'foggia', label: 'Foggia' },
    { value: 'forli-cesena', label: 'Forlì-Cesena' },
    { value: 'frosinone', label: 'Frosinone' },
    { value: 'genova', label: 'Genova' },
    { value: 'gorizia', label: 'Gorizia' },
    { value: 'grosseto', label: 'Grosseto' },
    { value: 'imperia', label: 'Imperia' },
    { value: 'isernia', label: 'Isernia' },
    { value: 'l-aquila', label: "L'Aquila" },
    { value: 'la-spezia', label: 'La Spezia' },
    { value: 'latina', label: 'Latina' },
    { value: 'lecce', label: 'Lecce' },
    { value: 'lecco', label: 'Lecco' },
    { value: 'livorno', label: 'Livorno' },
    { value: 'lodi', label: 'Lodi' },
    { value: 'lucca', label: 'Lucca' },
    { value: 'macerata', label: 'Macerata' },
    { value: 'mantova', label: 'Mantova' },
    { value: 'massa-e-carrara', label: 'Massa e Carrara' },
    { value: 'matera', label: 'Matera' },
    { value: 'medio-campidano', label: 'Medio Campidano' },
    { value: 'messina', label: 'Messina' },
    { value: 'milano', label: 'Milano' },
    { value: 'modena', label: 'Modena' },
    { value: 'monza-e-brianza', label: 'Monza e Brianza' },
    { value: 'napoli', label: 'Napoli' },
    { value: 'novara', label: 'Novara' },
    { value: 'nuoro', label: 'Nuoro' },
    { value: 'ogliastra', label: 'Ogliastra' },
    { value: 'olbia-tempio', label: 'Olbia-Tempio' },
    { value: 'oristano', label: 'Oristano' },
    { value: 'padova', label: 'Padova' },
    { value: 'palermo', label: 'Palermo' },
    { value: 'parma', label: 'Parma' },
    { value: 'pavia', label: 'Pavia' },
    { value: 'perugia', label: 'Perugia' },
    { value: 'pesaro-e-urbino', label: 'Pesaro e Urbino' },
    { value: 'pescara', label: 'Pescara' },
    { value: 'piacenza', label: 'Piacenza' },
    { value: 'pisa', label: 'Pisa' },
    { value: 'pistoia', label: 'Pistoia' },
    { value: 'pordenone', label: 'Pordenone' },
    { value: 'potenza', label: 'Potenza' },
    { value: 'prato', label: 'Prato' },
    { value: 'ragusa', label: 'Ragusa' },
    { value: 'ravenna', label: 'Ravenna' },
    { value: 'reggio-calabria', label: 'Reggio Calabria' },
    { value: 'reggio-emilia', label: 'Reggio Emilia' },
    { value: 'rieti', label: 'Rieti' },
    { value: 'rimini', label: 'Rimini' },
    { value: 'roma', label: 'Roma' },
    { value: 'rovigo', label: 'Rovigo' },
    { value: 'salerno', label: 'Salerno' },
    { value: 'sassari', label: 'Sassari' },
    { value: 'savona', label: 'Savona' },
    { value: 'siena', label: 'Siena' },
    { value: 'siracusa', label: 'Siracusa' },
    { value: 'sondrio', label: 'Sondrio' },
    { value: 'taranto', label: 'Taranto' },
    { value: 'teramo', label: 'Teramo' },
    { value: 'terni', label: 'Terni' },
    { value: 'torino', label: 'Torino' },
    { value: 'trapani', label: 'Trapani' },
    { value: 'trento', label: 'Trento' },
    { value: 'treviso', label: 'Treviso' },
    { value: 'trieste', label: 'Trieste' },
    { value: 'udine', label: 'Udine' },
    { value: 'varese', label: 'Varese' },
    { value: 'venezia', label: 'Venezia' },
    { value: 'verbano-cusio-ossola', label: 'Verbano Cusio Ossola' },
    { value: 'vercelli', label: 'Vercelli' },
    { value: 'verona', label: 'Verona' },
    { value: 'vibo-valentia', label: 'Vibo Valentia' },
    { value: 'vicenza', label: 'Vicenza' },
    { value: 'viterbo', label: 'Viterbo' }
  ]

  return (
    <FormContainer>
      {isLoading && <Loading />}
      <form id={'preventivatore'} onSubmit={formSubmit}>
        {isTfs ? <TfsFormHeader /> : <PreventivatoreFormHeader />}
        <FirstSection>
          <FirstSessionWrapper>
            <SelectsContainer>
              <SelectsRow>
                <SelectContainer>
                  <Select
                    name={'tipologia'}
                    options={typeList}
                    placeHolderText={'IL MIO PROGETTO'}
                    icon={dreamIcon}
                    dataFromSelect={({ value }: any) => {
                      setValue('tipologia', value, { shouldDirty: true })
                    }}
                  />
                </SelectContainer>
                <SelectContainer>
                  <Select
                    required={true}
                    name={'macroprofessione'}
                    options={customerCategoryList}
                    placeHolderText={'CHI SONO*'}
                    icon={identityIcon}
                    dataFromSelect={({ value }: any) => {
                      setValue('macroprofessione', value, { shouldDirty: true })
                    }}
                  />
                </SelectContainer>
                <SelectContainer>
                  <Select
                    required={true}
                    name={'importo'}
                    options={amountList}
                    placeHolderText={'IMPORTO*'}
                    icon={moneyIcon}
                    dataFromSelect={({ value }: any) => {
                      setValue('importo', value, { shouldDirty: true })
                    }}
                  />
                </SelectContainer>
                <SelectContainer>
                  <Select
                    required={true}
                    name={'durata'}
                    options={durationList}
                    placeHolderText={'DURATA*'}
                    icon={timeIcon}
                    dataFromSelect={({ value }: any) => {
                      setValue('durata', value, { shouldDirty: true })
                    }}
                  />
                </SelectContainer>
              </SelectsRow>
              {!isTfs && (
                <AnticipoTFSLinkContainer>
                  <A onClick={() => navigate('/anticipo-fs')} className="cta-tfs">
                    <span style={{ color: '#fff' }}>SCOPRI ANCHE IL NOSTRO&nbsp;</span>
                    <Em>ANTICIPO TFS</Em>
                  </A>
                </AnticipoTFSLinkContainer>
              )}
            </SelectsContainer>
          </FirstSessionWrapper>
        </FirstSection>
        <SecondSection>
          <Container>
            <Row>
              <Filler />
              <FormInput
                control={control}
                name={'nome'}
                type={'text'}
                id={'name'}
                placeholder={'Nome*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'cognome'}
                type={'text'}
                id={'surname'}
                placeholder={'Cognome*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'annonascita'}
                type={'number'}
                id={'birthday'}
                placeholder={'Anno di nascita*'}
                min={'1919'}
                max={'2001'}
                required={true}
              />
              <FormInput
                control={control}
                name={'citta'}
                type={'text'}
                id={'city'}
                placeholder={'Città*'}
                required={true}
              />
              <ProvinceSelectContainer>
                <NativeSelect
                  required={true}
                  name={'provincia'}
                  options={provinceList}
                  placeHolderText={'Provincia*'}
                  dataFromSelect={(value) => {
                    setValue('provincia', value, { shouldDirty: true })
                  }}
                />
              </ProvinceSelectContainer>
              <FormInput
                control={control}
                name={'telefono'}
                type={'tel'}
                id={'telefono'}
                placeholder={'Telefono*'}
                minLength={8}
                required={true}
              />
              <FormInput
                control={control}
                name={'email'}
                type={'email'}
                id={'email'}
                placeholder={'Email*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'conferma_email'}
                type={'email'}
                id={'email-confirmation'}
                placeholder={'Conferma Email*'}
                autocomplete={'new-password'}
                required={true}
              />

              <FormCollapsableContainer>
                <FormCollapsable innerTextBackgroundColor={'#222'} />
              </FormCollapsableContainer>
              <Checkbox1Container>
                <label htmlFor={'privacy'}>
                  <input style={{ margin: '3px 3px 3px 4px' }} type={'checkbox'} {...register('p2')} id={'privacy'} />
                  <Small>
                    &nbsp;Desidero ricevere comunicazioni su iniziative promozionali curate dalla Banca e relative a
                    prodotti della Banca (compreso marketing diretto) o di società Terze, ivi compresa l’organizzazione
                    di eventi, anche attraverso sistemi automatizzati (sms, mms, fax e posta elettronica) – Finalità 3
                    lettera a) e c) dell’informativa privacy.
                  </Small>
                </label>
              </Checkbox1Container>
              <Checkbox2Container>
                <label htmlFor={'marketing'}>
                  <input style={{ margin: '3px 3px 3px 4px' }} type={'checkbox'} {...register('p3')} id={'marketing'} />
                  <Small>
                    &nbsp;Desidero ricevere comunicazioni informative o offerte promozionali da Terzi, anche attraverso
                    sistemi automatizzati (sms, mms, fax e posta elettronica), di loro prodotti o servizi - Finalità 3
                    lettera d) dell’informativa privacy.
                  </Small>
                </label>
              </Checkbox2Container>
              <SubmitButton />
              <DisclaimerContainer>
                <P style={{ marginBottom: 0 }}>
                  <Small>* Campi obbligatori.</Small>
                </P>
                <P style={{ marginBottom: 0 }}>
                  <Small>
                    ** L'invio della richiesta di preventivo è gratis e senza impegno ed equivale al conferimento del
                    consenso per la finalità di cui al punto 2 dell’informativa, anche per l’utilizzo di tecniche di
                    comunicazione a distanza, facoltativo ma necessario per offrirti il servizio.{' '}
                    <a style={{ color: 'inherit', textDecoration: 'underline' }} onClick={() => navigate('/privacy')}>
                      Informativa privacy completa
                    </a>
                    .
                  </Small>
                </P>
              </DisclaimerContainer>
            </Row>
          </Container>
        </SecondSection>
      </form>
    </FormContainer>
  )
}

// region Style
const FormContainer = styled.div`
  margin: 0 auto;
  margin-top: -16.4rem;
  min-height: 16rem;
  background-color: rgba(34, 34, 34, 0.85);
  position: relative;
  max-width: 114rem;
  z-index: 30;
  border-radius: 4.5rem;
  display: block;
`
const Em = styled.em`
  font-style: normal;
  color: #fcbb00;
`
const FirstSection = styled.div`
  min-height: 7.6rem;
`
const FirstSessionWrapper = styled.div`
  position: relative;
  padding-bottom: 1.5rem;
  width: 100%;
  max-width: 114rem;
  transition: padding 0.1s ease-out;
  z-index: 10;
`
const SelectsContainer = styled.div`
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`
const SelectsRow = styled.div`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  justify-content: flex-end !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SelectContainer = styled.div`
  @media (min-width: 992px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
`
const AnticipoTFSLinkContainer = styled.div`
  text-align: center !important;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
`
const A = styled.a`
  cursor: pointer;
  color: inherit;
  border-left: solid 4px #fcbb00;
  padding: 1px 0 0px 10px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
`
const SecondSection = styled.div`
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 70%);
  background-color: #fff;
  position: relative;
  display: block;
  padding-bottom: 5rem;
  border-radius: 0 0 4.5rem 4.5rem;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Filler = styled.div`
  margin-bottom: 3rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const InputContainer = styled.div`
  @media (min-width: 576px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  margin-bottom: 3rem !important;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Input = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: calc(1rem + 0.25em) 2rem 1rem;
  height: 4.6rem;
  border-radius: 2.3rem;
  color: #222;
  font-size: 1.3rem;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  font-weight: 400;
  letter-spacing: 0.5px;
`
const FormCollapsableContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Checkbox1Container = styled.div`
  text-align: initial;
  @media (min-width: 768px) {
    margin-left: 16.66667%;
  }
  @media (min-width: 768px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  padding-top: 3rem !important;
  padding-bottom: 1rem !important;
  margin-top: 3rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Checkbox2Container = styled.div`
  text-align: initial;
  @media (min-width: 768px) {
    margin-left: 16.66667%;
  }
  @media (min-width: 768px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  padding-bottom: 1.5rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Small = styled.small`
  font-size: 1.2rem;
`
const DisclaimerContainer = styled.div`
  text-align: initial;
  @media (min-width: 576px) {
    margin-left: 8.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  @media (min-width: 768px) {
    margin-left: 16.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const ProvinceSelectContainer = styled.div`
  @media (min-width: 576px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  margin-bottom: 3rem !important;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  select::placeholder {
    color: black;
  }
`
// endregion

export default PreventivatoreForm
